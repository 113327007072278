import {load} from "recaptcha-v3";

const getSiteKey = () => {
  const meta = document.querySelector("meta[name='recaptcha-site-key']");
  if (!meta) throw new Error("reCAPTCHA site key not found");
  return meta.content;
};

export const getRecaptchaToken = async (action) => {
  const recaptcha = await load(getSiteKey(), {
    useRecaptchaNet: true,
    autoHideBadge: true
  });

  return await recaptcha.execute(action);
};
